.privacy-page {
  margin-top: 90px;
  font-family: var(--text-font);
  color: #1A202C;
  color: var(--text-grey);
  line-height: 1.8;
}

.privacy-page .checkbox {
  height: 25px;
  width: 25px;
  margin-left: 20px;
  transform: translate(0, 5px);
}

.privacy-page .privacy-btn {
  background-color: grey;
  color: var(--white-text);
  border-radius: 3px !important;
  padding: 3px 15px;
  border: none !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  font-family: var(--text-font);
  font-size: 1rem;
}

.privacy-page .privacy-btn.allowed {
  background-color: rgb(28, 116, 28);
}

.privacy-page .privacy-btn.disallowed {
  background-color: rgb(9, 128, 9);
}
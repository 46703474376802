.mosaic {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.mosaic-image {
  width: 140px;
  height: 90px;
  overflow: hidden;
  margin: 15px;
  border-radius: 3px;
  opacity: 0.9;
  transition: opacity 0.25s;
}

.mosaic-image:hover {
  opacity: 1;
}

@media (max-width: 550px) {
  .mosaic-image {
    margin: 10px;
    width: 75px;
    height: 52px;
    border-radius: 1px;
  }
}

@media (max-width: 900px) {
  .mosaic {
    position: relative;
    top: 0;
    transform: translateY(0);
    width: 75%;
    margin: 0 auto;
    margin-top: 90px;
    padding: 20px;
  }

  .mosaic-image {
    margin: 10px;
  }

  .mosaic-image:nth-of-type(7),
  .mosaic-image:nth-of-type(8) {
    display: none;
  }
}

@media (max-width: 1100px) {
  .mosaic-image:nth-of-type(9),
  .mosaic-image:nth-of-type(10),
  .mosaic-image:nth-of-type(11),
  .mosaic-image:nth-of-type(12) {
    display: none;
  }
}

@media (max-width: 1400px) {
  .mosaic-image:nth-of-type(13),
  .mosaic-image:nth-of-type(14),
  .mosaic-image:nth-of-type(15),
  .mosaic-image:nth-of-type(16) {
    display: none;
  }
}

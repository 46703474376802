.conferences {
  margin-top: 70px;
  font-family: var(--text-font);
  color: #2D3748;
  line-height: 1.8;
}

.conferences h1 {
  color: #1A202C;
}

.conferences-page-title {
  font-size: 2em;
  margin: 0 40px -10px;
}

@media (min-width: 550px) {
  .conferences-page-title {
    margin: 0 65px -10px;
  }
}

.conferences-public {
  background-color: #E2E8F0;
}


.conferences-public .event-list article h1 {
  font-size: 2.5em;
}

.conferences-public .event-list article a {
  color: #1A202C;
  color: var(--text-grey)
}

.conferences-public .event-list article h1, .conferences-public .event-list article h5, .conferences-public .event-list article p {
  margin: 5px auto;
}
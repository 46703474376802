/* autoprefixer grid: autoplace */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: #F0FFF4;
}

.container {
  margin: 0 auto;
  width: 80%;
  max-width: 42em;
  margin: 0 auto;
}

:root {
  --white-text: rgb(235, 241, 235);
  --link-text-size: 20px;
  --text-font: 'Poppins', sans-serif;
  --white-text-hover: rgb(255, 255, 255);
  --text-grey: #1A202C;
  --lighter-text-grey: #272d3a;
  --first-alternating-colour: rgba(161, 161, 161, 0.5);
  --second-alternating-colour: rgba(201, 201, 241, 0.5);
  --header-colour: rgb(49, 104, 53);
  --handle-vars-nav-background: unset;
}

.btn {
  text-decoration: none;
  border-radius: 10px;
  color: var(--white-text);
  font-family: var(--text-font);
  width: 150px;
  /* height: 38px; */
  padding: 5px 15px;
  font-size: 20px;
  display: inline-block;
  text-align: center;
  transition: color 0.25s, background-color 0.25s;
  border: none;
}

.button:hover {
  color: white;
}

.primary-btn {
  background-color: #60B74A;
}

.primary-btn:hover {
  background-color: rgb(63, 148, 42);
}

.secondary-btn {
  background-color: #226FB7;
}

.secondary-btn:hover {
  background-color: rgb(17, 78, 134);
}

.sign-out-btn {
  border: none;
  background-color: #226FB7;
  color: var(--white-text);
  width: 140px;
  margin: 10px 0;
  padding: 3px;
}

.sign-out-btn:hover {
  background-color: rgb(17, 78, 134);
}

.page {
  min-height: 500px;
  min-height: 70vh;
}

.basic-box-shadow {
  box-shadow: 0px 1px 4px 0px #00000091;
}

.red-text {
  color: rgb(255, 38, 30);
}

.green-text {
  color: rgb(35, 218, 35);
}

.small-vertical-padding {
  padding: 40px 0;
}

.medium-vertical-padding {
  padding: 60px 0;
}

.large-vertical-padding {
  padding: 90px 0;
}

.small-padding {
  padding: 40px;
}

.medium-padding {
  padding: 60px;
}

.large-padding {
  padding: 90px;
}

.center {
  text-align: center;
}

.full-height-page {
  min-height: 500px;
  min-height: 75vh;
}
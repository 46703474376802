.header {
  position: fixed;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  display: block;
  transition: background-color 0.3s;
  z-index: 2;
  /* ie support background */
  background-color: rgb(49, 104, 53);
  background-color: var(--handle-vars-nav-background);
}

.logo {
  display: inline-block;
  margin-left: 25px;
}

.logo img {
  width: 180px;
}

.header nav .desktop-menu li {
  display: inline;
  padding: 0;
  display: inline;
  margin: 0px;
}

.desktop-menu {
  display: none;
}

.header nav .desktop-menu li a {
  text-decoration: none;
  font-size: 20px;
  font-size: var(--link-text-size);
  color:rgb(235, 241, 235);
  color: var(--white-text);
  font-family: var(--text-font);
  font-weight: 400;
  transition: color 0.25s;
}

.header nav .desktop-menu li a:hover {
  color: rgb(255, 255, 255);
  color: var(--white-text-hover);
}

.menu-btn {
  display: none;
}

.menu-line {
  width: 96%;
  margin: 7px auto;
  background-color: rgb(255, 253, 253);
  background-color: var(--white-text);
  transition: background-color 0.25s;
  border-radius: 10px;
  height: 3px;
}

.menu-btn:hover .menu-line {
  background-color: rgb(255, 255, 255);
  background-color: var(--white-text-hover);
}

.public-header .close-mob-nav-cross {
  color: white;
  font-size: 40px;
  line-height: 20px;
}

.headerBackgroundColour {
  background-color: rgb(49, 104, 53);
  background-color: var(--header-colour);
}

.desktop-menu li .show-nav-location.active {
  transition: border-bottom 1s;
  border-bottom: solid rgba(255, 255, 255, 0) 2px;
}

.desktop-menu li .show-nav-location.active {
  transition: border-bottom 1s;
  border-bottom: solid blue 2px;
}

.public-header nav ul li a {
  padding: 0 0.6em;
  padding: 0 0.4vw;
}

.menu-btn .accessible-text {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

@media (min-width: 900px) {
  .header nav {
    display: flex;
  }

  .header nav:not(.mob-nav) {
    width: 100%;
  }

  .header nav .desktop-menu {
    display: inline;
    list-style: none;
    padding: 0;
    margin: 25px 15px 0 auto;
  }
}

@media (max-width: 900px) {
  .header {
    background-color: rgb(49, 104, 53);
    background-color: var(--header-colour);
  }

  .menu-btn {
    display: block;
    top: 25px;
    right: 10px;
    height: 40px;
    width: 40px;
    border-radius: 5px;
    position: absolute;
    border: none;
    background-color: rgb(49, 104, 53);
    background-color: var(--header-colour);
  }
}

@media (max-width: 700px) {
  .logo {
    margin-left: 0;
  }
}

@media (min-width: 1280px) {

  .public-header nav .desktop-menu li {
    padding: 0 0.6em;
    padding: 0 0.5vw;
  }
}
.reset-password-page {
  font-family: 'Poppins', sans-serif;
}

.reset-password-heading {
  margin: 10px auto; 
}

.form-reset {
  display: block;
  background-color:rgb(174 174 180);
  border-radius: 3px;
  padding: 30px;
}

.form-reset input[type="email"] {
  padding: 10px 5px;
  margin: 10px auto;
  font-size: 1em;
  display: block;
  width: 100%;
  border-radius: 3px;
  border: none;
}

.reset-btn {
  max-width: unset;
  width: unset;
  display: block;
  margin: 30px auto 5px;
}

.password-reset-max-width {
  word-wrap: break-word;
}

.password-reset-max-width h3 {
  margin: 2.5px 0;
}

.password-reset-max-width p {
  font-size: 0.9em;
  margin: 2.5px 0;
}
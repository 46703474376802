.footer {
  background-color: #5c5858;
  height: 250px;
  display: flex;
  z-index: 1;
  position: absolute;
  width: 100%;
}

.footer-logo {
  width: 180px;
}

.footer-details p {
  margin: 0;
}

.footer-lists {
  display: inline-block;
  margin: 0;
}

.footer-details {
  display: inline-block;
  margin: 0;
  margin: 0 0 0 auto;
  padding: 30px 0;
  font-family: var(--text-font);
}

.footer-details .author-link {
  font-size: 14px;
  color: rgb(201, 200, 200);
}

.footer-col {
  list-style: none;
  padding-left: 0;
  margin: 0;
  padding: 30px 0;
}

.footer-col li {
  padding: 0 10px;
}

.footer-col li a {
  text-decoration: none;
  font-family: var(--text-font);
  color: white;
  color: var(--white-text);
}

.footer-col li a:hover {
  color: rgb(168, 167, 167);
}

.footer .footer-details .author-link a {
  color: white;
  color: var(--white-text);
}

.footer .footer-details .author-link a:hover {
  color: rgb(168, 167, 167);
}

.footer .container {
  display: flex;
}


@media (max-width: 550px) {
  .footer-logo {
    width: 120px;
    padding: 15px;
    margin: auto;
  }
}
.join-form-container {
  background-color: #F7FAFC;
  font-family: var(--text-font);
  padding: 60px 0;
  line-height: 1.7;
}

.join-form-container .red-text {
  color: red;
  font-size: 1.5rem;
}

.join-form {
  box-shadow: 0 2px 9px 0 #232c2566;
  padding: 30px;
  background: white;
}

.join-form .form-row {
  display: flex;
}

.join-form .label {
  font-size: 16px;
}

.join-form--text {
  display: none;
}

.join-form--checkbox {
  display: none;
}

.join-form input[type="checkbox"] {
  height: 40px;
  width: 40px;
  margin: 0 20px;
}

.join-form input[type="text"] {
  margin: 5px auto;
  font-family: var(--text-font);
  padding: 0 5px;
  margin: 10px auto;
}

.join-form input[type="submit"] {
  margin: 10px auto;
}

.join-form .inline-elem {
  display: inline-block;
}

.join-form--reason-text {
  font-size: 0.9rem;
}

.animated-form-notice {
  animation-name: formNotice;
  animation-duration: 0.5s;
  animation-timing-function: ease;

}

@keyframes formNotice {
  0% {
    opacity: 0;
    transform: translate(0, 30px);
  }

  13% {
    opacity: 1;
    transform: translate(0, -30px);
  }

  36% {
    transform: translate(0, 15px);
  }

  65% {
    transform: translate(0, -15px);
  }

  84% {
    transform: translate(0, 5px);
  }

  100% {
    transform: translate(0, 0px);
  }
}

.google-form-link {
  font-family: var(--text-font);
  font-size: 1.3rem;
  padding: 5px 10px;
  text-decoration: none;
  display: inline-block;
  border: solid 0.5px grey;
  border-radius: 3px;
  background-color: white;
  color: rgb(43, 43, 43);
  color: var(--text-grey);
  transition: background-color 0.3s, color 0.3s;
}

.google-form-link:hover {
  background-color: grey;
  background-color: var(--text-grey);
  color: white;
}
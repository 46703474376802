.event-item {
  margin: 100px auto;
  font-family: var(--text-font);
  color: #2D3748;
  line-height: 1.8;
}

.conference-back-btn--container {
  width: 80%;
  max-width: 42em;
  margin: 0 auto;
  padding-top: 10px;
}

.conference-back-btn {
  font-weight: 700;
}


.event-post-date {
  margin: 5px auto;
}

.event-post-date--updated {
  color: rgb(29, 151, 13);
}

.event-title {
  margin: 10px auto;
  font-size: 1.5em;
}

.event-date {
  font-weight: 400;
  margin: 5px auto;
}
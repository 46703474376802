.jumbotron {
  display: flex;
  height: 100vh;
}

.jumbo-text-container {
  background-color: #4FA753;
  width: 50%;
}

.jumbo-text {
  color: white;
  color: var(--white-text);
  font-family: var(--text-font);
  margin: 200px auto;
  padding: 20px;
  word-wrap: break-word;
}

.jumbo-text h1 {
  font-size: 72px;
  font-weight: 400;
  margin: 0;
}

.jumbo-text h2 {
  font-size: 24px;
  font-weight: 400;
}

.jumbo-text .secondary-btn {
  margin: 0 20px;
}

.jumbo-image-container {
  background-color: #96CB99;
  position: relative;
  width: 50%;
}

.news-background {
  background-color: #EDF2F7;
  height: auto;
  padding-bottom: 80px;
}

.news-background .three-panel {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin: 0 auto;
  width: 75%;
  transform: translate(0, -20px);
}

.news-background .three-panel .three-panel--panel {
  background-color: white;
  border-radius: 10px;
  padding: 23px;
  font-family: var(--text-font);
  text-align: left;
  box-shadow: 0px 6px 12px 0px #00000029;
  margin: 0 10px;
}

.news-background .three-panel .three-panel--panel:nth-of-type(1) {
  -ms-grid-row:1; 
   -ms-grid-column:1; 
}

.news-background .three-panel .three-panel--panel:nth-of-type(2) {
  -ms-grid-row: 1; 
   -ms-grid-column: 2; 
}

.news-background .three-panel .three-panel--panel:nth-of-type(3) {
  -ms-grid-row: 1; 
   -ms-grid-column: 3; 
}




.news-background .three-panel .three-panel--panel h3 {
  text-align: center;
  font-family: var(--text-font);
  margin: 10px auto 5px;
  color: var(--text-grey);
}

.news-background .three-panel .three-panel--panel p {
  margin: 0px auto;
  color: var(--lighter-text-grey);
  line-height: 30px;
  font-size: 0.85rem;
  padding: 0 15px;
}

.news-background .three-panel .three-panel--panel .panel-link-button {
  border: solid rgb(20, 20, 20) 2px;
  padding: 8px;
  border-radius: 3px;
  color: rgb(20, 20, 20);
  text-align: center;
  display: inline-block;
  width: 200px;
  height: auto;
  margin: 0 auto;
  text-decoration: none;
  font-family: var(--text-font);
}

.news-background .more-info {
  font-family: var(--text-font);
}

.more-info {
  padding: 30px;
  background-color: #E2E8F0;
}

.more-info .more-info--element {
  padding: 60px;
  font-family: var(--text-font);
  box-shadow: 0 2px 9px 0 #232c2566;
  border-radius: 4px;
  margin: 60px auto;
  background-color: #EDF2F7;
  color: #1A202C;
  line-height: 2;
  max-width: 42em;
}

.more-info .more-info--element p {
  margin: 5px 0 15px;
  color: #2D3748;
}

.more-info .more-info--element h3 {
  margin: 10px 0;
  color: #1A202C;
}

.dark-background {
  background-color: #1A202C;
  color: #CBD5E0;
  line-height: 1.7;
}

.dark-background h3 {
  color: #E2E8F0;
  margin: 5px auto;
}

.dark-background p {
  margin: 5px auto;
}

.joining-cape-btn {
  display: inline-block;
  position: absolute;
  right: 200px;
}

.down-arrow {
  height: 18px;
  transform: translate(2px, 2px);
}

@media (max-width: 480px) {}

@media (max-width: 550px) {

  .jumbo-text-container {
    padding-top: 15px;
  }

}

@media (max-width: 900px) {
  .jumbotron {
    display: block;
    height: auto;
  }

  .jumbo-image-container {
    display: block;
    width: 100%;
  }

  .jumbo-text-container {
    display: block;
    width: 100%;
  }

  .jumbo-text {
    margin: 0px auto;
    padding: 60px;
  }

  .jumbo-text h1 {
    font-size: 3rem;
  }

  .jumbo-text h2 {
    font-size: 20px;
    margin-bottom: 60px;
  }

  .jumbo-text .btn {
    display: block;
    width: auto;
    margin: 20px auto;
    max-width: 300px;
  }


  .news-background {
    height: auto;
    padding-bottom: 0px;
    padding: 20px;
  }

  .news-background .three-panel {
    display: block;
  }

  .news-background .three-panel .three-panel--panel {
    margin: 40px auto 0;
    max-width: 380px;
    padding: 60px;
  }


  .joining-cape-btn {
    display: inline-block;
    margin: 0 auto;
    right: 100px;
  }
}

@media (max-width: 1300px) {
  .news-background {
    height: auto;
  }

  .news-background .three-panel {
    width: 90%;
  }

  .news-background .three-panel .three-panel--panel {
    padding: 15px;
  }

  .news-background .three-panel .three-panel--panel .panel-link-button {
    padding: 8px;
    width: 160px;
  }
}
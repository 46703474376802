.form-element {
  max-width: 400px;
  margin: auto;
  font-family: var(--text-font);
}

.form-element .login-title, .form-element .login-sub-title {
  font-family: var(--text-font);
  color: #1A202C;
  margin: 0 5px;
}

.form-element form {
  border-radius: 5px;
  padding: 15px;
  margin: 10px auto 60px;
  background: #EDF2F7;
  background-color: whitesmoke;
  box-shadow: 0px 3px 12px 1px #00000033;
}

.form-element input {
  display: block;
  font-family: var(--text-font);
  font-size: 1rem;
  margin: 10px auto;
  padding: 10px;
}

.form-element input[type="text"] {
  display: block;
  margin: 5px auto;
  margin: 10px auto;
  width: 90%;
}

.form-element input[type="password"] {
  display: block;
  margin: 5px auto;
  margin: 10px auto;
  width: 90%;
}

.form-element input[type="submit"] {
  margin: 15px auto;
  font-weight: 900;

}

.form-element form .label {
  font-weight: 900;
  display: block;
  text-align: center;
  color: var(--text-grey);
}

.form-element .remember-me-line {
  text-align: left;
  padding: 0 15px;
  display: flex;
}

.form-element .remember-me-line input, .form-element .remember-me-line p  {
  display: inline-block;
}

.form-element .remember-me-line input {
  margin: 0 0 0 auto;
  height: 25px;
  width: 25px;
}

.login-error-animation {
  animation: formNotice 0.5s ease;
}